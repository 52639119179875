<template>
  <div
    ref="$el"
    class="video"
    :class="theme"
  >
    <img
      v-if="!isPlaying && !isLoading"
      alt="Play"
      class="playing-button"
      height="60"
      loading="lazy"
      src="@/assets/svg/play.svg"
      width="60"
      @click="playOrPause"
    >
    <div
      class="gradient"
      :class="{ 'is-loading': isLoading }"
    />
    <div
      v-show="isLoading"
      class="loader"
    />
    <video
      ref="$video"
      playsinline
      :poster="poster"
    />
    <div
      v-if="subtitles.length > 0"
      class="subtitles"
    >
      <div
        v-for="{ id, content } in subtitles"
        :key="id"
        class="item"
      >
        {{ content }}
      </div>
    </div>
    <div class="controls">
      <div class="play-or-pause">
        <UiComponentIcon
          :type="playOrPauseIcon"
          @click="playOrPause"
        />
      </div>
      <div class="time">
        {{ currentTime }}
      </div>
      <div
        class="bar"
        @click="progressBarClicked"
      >
        <div
          class="current"
          :style="{ width: `${currentTimeInPercentage}%` }"
        />
        <div class="circle" />
        <div
          class="duration"
          :style="{ width: `${remainingTimeInPercentage}%` }"
        />
      </div>
      <div class="volume">
        <div
          v-if="theme === 'dark'"
          class="volume-icon"
          @click="volumeIconClicked"
        >
          <lottie-player
            v-if="isLottieFrameworkLoaded && isMuted && !isWiggling"
            autoplay
            class="sound-off"
            src="/lottie/player/sound-off.json"
          />
          <lottie-player
            v-if="isLottieFrameworkLoaded && isMuted && isWiggling"
            autoplay
            class="sound-wiggle"
            src="/lottie/player/sound-wiggle.json"
          />
          <lottie-player
            v-if="isLottieFrameworkLoaded && !isMuted"
            autoplay
            class="sound-on"
            src="/lottie/player/sound-on.json"
          />
        </div>
        <div
          v-else
          class="volume-icon"
          @click="volumeIconClicked"
        >
          <UiComponentIcon :type="volumeIcon" />
        </div>
      </div>
      <div class="fullscreen">
        <UiComponentIcon
          :type="maximizeOrMinimizeIcon"
          @click="toggleFullscreen"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { gsap } from 'gsap'

// Composables
const { $hls, $lottiePlayer } = useNuxtApp()

// Emitter
const emit = defineEmits([
  'player:click',
])

// Props
const props = defineProps({
  initializeImmediately: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  locked: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  muted: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  posterSrc: {
    required: true,
    type: String,
  },
  subtitles: {
    default() {
      return []
    },
    required: false,
    type: Array,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  videoSrc: {
    required: true,
    type: String,
  },
})

// Refs
const $el = ref(null)
const $video = ref(null)
const count = ref(0)
const currentTime = ref('00:00')
const currentTimeInPercentage = ref(0)
const eventType = ref('click')
const intervalId = ref(null)
const isFullscreen = ref(false)
const isInitialized = ref(false)
const isLoading = ref(false)
const isLottieFrameworkLoaded = ref(false)
const isPlaying = ref(false)
const isMuted = ref(false)
const isWiggling = ref(false)
const requestAnimationFrameId = ref(null)
const timeoutId = ref(null)
const totalVolumeInPercentage = ref(0)

// Variables
let subtitlesTimeline = null

// Computed Properties
const currentVolumeInPercentage = computed(() => {
  return 100 - totalVolumeInPercentage.value
})

const maximizeOrMinimizeIcon = computed(() => {
  return isFullscreen.value ? 'exit-fullscreen' : 'go-fullscreen'
})

const playOrPauseIcon = computed(() => {
  if (!isInitialized.value) {
    return 'play'
  }
  return isPlaying.value ? 'pause' : 'play'
})

const poster = computed(() => {
  if (props.posterSrc.includes('?')) {
    return props.posterSrc
  }
  else {
    return `${props.posterSrc}?auto=format`
  }
})

const remainingTimeInPercentage = computed(() => {
  return 100 - currentTimeInPercentage.value
})

const volumeIcon = computed(() => {
  return isMuted.value ? 'volume-x' : 'volume-1'
})

// Methods
function bindEvents() {
  const options = 'ontouchstart' in document ? { passive: true } : {}
  eventType.value = 'ontouchstart' in document ? 'touchstart' : 'click'
  $video.value.addEventListener('pause', onPause)
  $video.value.addEventListener(eventType.value, playOrPause, options)
  $video.value.addEventListener('ended', onEnded)
  $el.value.querySelector('.gradient').addEventListener(eventType.value, playOrPause, options)
  $el.value.querySelector('.circle').addEventListener('touchmove', dragToCurrentTime, options)
}

function createSubtitlesTimeline(totalDuration) {
  if (props.subtitles.length === 0) {
    return
  }

  const $items = [...$el.value.querySelectorAll('.subtitles .item')]

  subtitlesTimeline = gsap.timeline()
  subtitlesTimeline.set($items[0], { display: 'none' }, 0)
  props.subtitles.forEach(({ duration, startAt }, index) => {
    subtitlesTimeline.set($items[index], { display: 'flex' }, startAt)
    subtitlesTimeline.set(
      $items[index],
      { display: 'none' },
      startAt + duration,
    )
  })
  subtitlesTimeline.set(
    $items[$items.length - 1],
    { display: 'none' },
    totalDuration,
  )
  subtitlesTimeline.pause()
}

function dragToCurrentTime(e) {
  const { left, right } = $el.value
    .querySelector('.bar')
    .getBoundingClientRect()
  const pourcentOfVideo
    = ((e.changedTouches[0].clientX - left) * 100) / (right - left)
  nextTick(() => {
    $video.value.currentTime
      = (pourcentOfVideo * $video.value.duration) / 100
  })
}

function initializeVideo() {
  if (isInitialized.value) {
    return
  }

  isInitialized.value = true
  isLoading.value = true

  $hls.init(() => {
    /* eslint-disable */
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.on(Hls.Events.LEVEL_LOADED, (_, data) => {
        createSubtitlesTimeline(data.details.totalduration);
      });
      hls.once(Hls.Events.FRAG_LOADED, () => {
        start();
      });
      hls.loadSource(props.videoSrc);
      hls.attachMedia($video.value);
    } else if (
      $video.value.canPlayType("application/vnd.apple.mpegurl")
    ) {
      $video.value.addEventListener('canplaythrough', () => {
        createSubtitlesTimeline($video.value.duration);
        start();
      });
      $video.value.src = props.videoSrc;
      $video.value.load();
    }
    /* eslint-enable */
  })

  $lottiePlayer.init(() => {
    isLottieFrameworkLoaded.value = true
  })
}

function isOnFullscreenMode() {
  return Boolean(
    document.fullscreenElement
    || document.webkitFullscreenElement
    || document.mozFullScreenElement
    || document.msFullscreenElement,
  )
}

function pause() {
  isPlaying.value = false
  $video.value.pause()
}

function playOrPause() {
  initializeVideo()

  if (props.locked) {
    return
  }

  if ($video.value.paused) {
    isPlaying.value = true
    $video.value.play()
  }
  else {
    isPlaying.value = false
    $video.value.pause()
  }

  emit('player:click')
}

function progressBarClicked(e) {
  const pourcentOfVideo
    = ((e.x - e.currentTarget.getBoundingClientRect().left) * 100)
      / e.currentTarget.offsetWidth
  nextTick(() => {
    $video.value.currentTime
      = (pourcentOfVideo * $video.value.duration) / 100
  })
}

function onEnded() {
  $video.value.currentTime = 0
  isPlaying.value = false
}

function onPause() {
  isPlaying.value = false
}

function secondsToHumanReadableTime(timeInSeconds) {
  const minutes = Number.parseInt((timeInSeconds / 60) % 60)
    .toString()
    .padStart(2, '0')
  const seconds = Number.parseInt(timeInSeconds % 60)
    .toString()
    .padStart(2, '0')
  return `${minutes}:${seconds}`
}

function start() {
  isLoading.value = false

  if (isMuted.value) {
    volumeIconClicked()
  }

  isPlaying.value = true
  $video.value.play()

  requestAnimationFrameId.value = window.requestAnimationFrame(
    updateCurrentTime,
  )
}

function toggleFullscreen() {
  if (isOnFullscreenMode()) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    }
    else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    }
    else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen()
    }
    else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
    $el.value.classList.remove('fullscreen')
    isFullscreen.value = false
  }
  else {
    if ($el.value.requestFullscreen) {
      $el.value.requestFullscreen()
    }
    else if ($el.value.mozRequestFullScreen) {
      $el.value.mozRequestFullScreen()
    }
    else if ($el.value.webkitRequestFullScreen) {
      $el.value.webkitRequestFullScreen()
    }
    else if ($video.value.webkitEnterFullScreen) {
      $video.value.webkitEnterFullScreen()
    }
    else if ($el.value.msRequestFullscreen) {
      $el.value.msRequestFullscreen()
    }
    $el.value.classList.add('fullscreen')
    isFullscreen.value = true
  }
}

function unbindEvents() {
  $video.value.removeEventListener(eventType.value, playOrPause)
  $video.value.removeEventListener('pause', () => {})
  $video.value.removeEventListener('ended', onEnded)

  $el.value.querySelector('.gradient').removeEventListener(eventType.value, playOrPause)
  $el.value.querySelector('.circle').removeEventListener('touchmove', dragToCurrentTime)
}

function updateCurrentTime() {
  nextTick(() => {
    currentTimeInPercentage.value
      = ($video.value.currentTime / $video.value.duration) * 100
  })
  currentTime.value = secondsToHumanReadableTime(
    $video.value.currentTime,
  )
  requestAnimationFrameId.value = window.requestAnimationFrame(
    updateCurrentTime,
  )

  subtitlesTimeline?.progress(
    $video.value.currentTime / $video.value.duration,
  )
}

function volumeBarClicked(e) {
  const newVolume = 1 - Math.abs(e.layerY) / 72
  if (newVolume <= 0) {
    volumeIconClicked()
  }
  else {
    $video.value.volume = newVolume
    nextTick(() => {
      totalVolumeInPercentage.value = (Math.abs(e.layerY) / 72) * 100
    })
  }
}

function volumeIconClicked() {
  isMuted.value = !$video.value.muted
  $video.value.muted = isMuted.value
  nextTick(() => {
    totalVolumeInPercentage.value = isMuted.value ? 0 : 100
  })

  if (isMuted.value) {
    count.value++
    timeoutId.value = window.setTimeout(() => {
      window.clearInterval(intervalId.value)
      window.clearTimeout(timeoutId.value)
      isWiggling.value = true
      count.value++

      intervalId.value = window.setInterval(() => {
        count.value++
      }, 2000)
    }, 2000)
  }
  else {
    window.clearInterval(intervalId.value)
    window.clearTimeout(timeoutId.value)
    isWiggling.value = false
  }
  count.value++
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  window.cancelAnimationFrame(requestAnimationFrameId.value)

  unbindEvents()

  if (intervalId.value) {
    window.clearInterval(intervalId.value)
  }

  if (subtitlesTimeline) {
    subtitlesTimeline.kill()
    subtitlesTimeline = null
  }

  if (timeoutId.value) {
    window.clearTimeout(timeoutId.value)
  }
})

onMounted(() => {
  isMuted.value = props.muted

  bindEvents()

  if (!props.initializeImmediately) {
    return
  }

  initializeVideo()
})
</script>

<style lang="postcss" scoped>
.video {
  @apply relative w-full shadow-light4 rounded bg-winter-green-900;
  height: 56.25%;

  &:hover {
    .gradient {
      @apply opacity-100 visible;
    }

    .controls {
      @apply opacity-100 visible;
    }
  }

  &.fullscreen {
    video {
      @apply h-full object-cover !important;
    }
  }

  &.light {
    .playing-button {
      filter: invert(100%) sepia(7%) saturate(111%) hue-rotate(134deg)
        brightness(116%) contrast(100%);
    }

    .loader {
      &:after {
        border-color: #fff transparent #fff transparent;
      }
    }

    .controls {
      i {
        @apply text-white;
      }

      .time {
        @apply text-white;
      }

      .bar {
        .current {
          @apply bg-white;
        }

        .circle {
          @apply bg-white;
        }

        .duration {
          @apply bg-white;
        }
      }
    }
  }

  .playing-button {
    @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
    filter: drop-shadow(0px 16px 32px rgba(13, 22, 38, 0.04))
      drop-shadow(0px 8px 16px rgba(13, 22, 38, 0.04))
      drop-shadow(0px 4px 8px rgba(13, 22, 38, 0.04))
      drop-shadow(0px 2px 4px rgba(13, 22, 38, 0.04))
      drop-shadow(0px 1px 2px rgba(13, 22, 38, 0.04));
  }

  .gradient {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.2) 100%
    );
    @apply flex absolute inset-0 w-full h-full rounded opacity-0 invisible transition-all duration-300 ease-in-out;

    @screen lg {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 90%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }

    &.is-loading {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }

  .loader {
    @apply absolute top-1/2 left-1/2 w-20 h-20 inline-block transform -translate-x-1/2 -translate-y-1/2;

    &:after {
      border: 6px solid #12262b;
      border-color: #12262b transparent #12262b transparent;
      animation: loader 1.2s linear infinite;
      @apply block m-2 w-16 h-16 rounded-full content-[''];
    }
  }

  video {
    @apply rounded h-full w-full object-contain;
  }

  .subtitles {
    @apply absolute bottom-4 left-4 right-4 flex justify-center transition-none;

    @screen md {
      @apply left-6 right-6;
    }

    @screen xl {
      @apply left-12 right-12;
    }

    .item {
      @apply hidden h-auto w-auto p-3 text-white text-center text-xs;
      background: rgba(35, 43, 47, 0.85);
      border-radius: 8px;
      line-height: 18px;
    }
  }

  .controls {
    @apply absolute bottom-4 left-4 right-4 flex items-center text-white opacity-0 invisible transition-all ease-out duration-300;
    z-index: 2147483648;

    i {
      @apply text-winter-green-900 text-base leading-none;
    }

    .play-or-pause {
      @apply flex items-center justify-center w-5 h-5 cursor-pointer;
    }

    .time {
      max-width: 42px;
      @apply w-full ml-4 text-sm text-winter-green-900;
    }

    .bar {
      @apply ml-4 py-4 relative flex items-center h-4 self-center cursor-pointer w-11/12;

      @screen md {
        @apply py-0;
      }

      .current {
        height: 2px;
        @apply bg-winter-green-900;
      }

      .circle {
        margin: 0 2px;
        @apply w-3 h-3 bg-winter-green-900 rounded-full flex-shrink-0;
      }

      .duration {
        height: 2px;
        @apply w-full bg-winter-green-900 opacity-25;
      }
    }

    .volume {
      @apply relative flex ml-4 cursor-pointer;

      .volume-icon {
        @apply flex items-center justify-center ml-4 w-[19.2px] h-6;
      }
    }

    .fullscreen {
      @apply flex items-center justify-center ml-4 w-5 h-5 cursor-pointer flex-shrink-0;
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
